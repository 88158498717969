@import '~@micromag/core/scss/styles';
@import '~@micromag/viewer/scss/styles';

/**
 * Commonss
 */
@import 'commons/variables';
@import 'commons/placeholders';
@import 'commons/mixins';
@import 'commons/fonts';
@import 'commons/medias_queries';
@import 'commons/reset';
@import 'commons/general';

html, body, #app {
    width: 100%;
    height: 100%;
}

body {
    background: $color-background;
    color: $color-text;
    font-family: $font-text;
}

h1,h2,h3,h4,h5,h6 {
    font-family: $font-title;
    font-weight: 600;
}

p {
    font-family: $font-text;
}

strong {
    font-weight: 700;
}
