@import '../commons/variables';
@import '../commons/placeholders';
@import '../commons/mixins';

.container {
    position: absolute;
    width: 100%;
    height: 100%;
    inset: 0;
}
